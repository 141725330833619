import { mapGetters } from 'vuex'
import { getItemFullId } from '~~/utils/utils'

export default {
  methods: {
    onStartChat() {
      if (window.userlikeStartChat) {
        if (localStorage) localStorage.removeItem('uslk_drag')
        window.userlikeStartChat()
        // userlike.setData(this.info)
      }
      /* eslint-enable */
    }
  },

  computed: {
    ...mapGetters({
      productDetails: 'productDetails/productDetails'
    }),

    profile() {
      return this.$store.state.auth.user || {}
    },

    info() {
      const user = {
        email: this.profile.email || '',
        name: `${this.profile.firstName || ''} ${
          this.profile.lastName || ''
        }`.trim()
      }

      const custom = {
        id: getItemFullId(this.productDetails),
        itemType: this.productDetails.category || ''
      }

      return {
        user,
        custom
      }
    }
  }
}
