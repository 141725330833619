<template lang="pug">
.main-menu-mobile(ref="menu", :style="menuStyle")
  .main-menu-mobile__wrapper.is-flex(ref="wrapper", :class="menuClassList")
    .main-menu-mobile__menus-wrapper
      .main-menu-mobile__root-items(ref="rootItems")
        .main-menu-mobile__root-items--item.is-flex(v-for="item in items", :key="item.id", @click="onExpandHandler(item)")
          .main-menu-mobile__root-items--item-name.is-flex
            icon(v-if="item.icon", :icon-name="item.icon", icon-color="#2e2e2e")
            span {{ item.name }}
          i.fas.fa-chevron-right
      .main-menu-mobile__user-controls
        .main-menu-mobile__user-controls--item(v-for="item in subMenu", :key="item.id")
          .is-flex(v-if="item.on", @click="item.on")
            .main-menu-mobile__user-controls--item-name.is-flex
              icon(v-if="item.icon", :icon-name="item.icon", icon-color="#2e2e2e")
              span {{ item.name }}
            .badge(v-show="item.itemsCount") {{ item.itemsCount }}
          v-link.is-flex(:to="item.to", v-else)
            .main-menu-mobile__user-controls--item-name.is-flex
              icon(v-if="item.icon", :icon-name="item.icon", icon-color="#2e2e2e")
              span {{ item.name }}
            .badge(v-show="item.itemsCount") {{ item.itemsCount }}
      .main-menu-mobile__contacts
        .main-menu-mobile__contacts--phone
          div.has-text-weight-bold {{ $t('layout.mainMenu.questions') }}
          div {{ $t('layout.phones.us') }}
        a.button.primary-color.light.main-menu-mobile__contacts--email.is-flex(:href="emailUrl")
          icon(icon-name="email", icon-color="#2e2e2e")
          span {{ $t('layout.mainMenu.email') }}
        button.button.primary-color.light.main-menu-mobile__contacts--chat.is-flex(@click="onStartChat")
          icon(icon-name="liveChat", icon-color="#2e2e2e")
          span {{ $t('layout.mainMenu.chat') }}
      .main-menu-mobile__sites
        .main-menu-mobile__sites--title.has-text-weight-bold {{ $t('layout.mainMenu.otherGemstones') }}
        a.button.primary-color.light.main-menu-mobile__sites--item(v-for="item in sitesList", :key="item.id", :href="item.href", target="_blank")
          icon(:icon-name="item.icon", :icon-color="item.color")
          span {{ item.name }}

    .main-menu-mobile__sub-items.is-borderless(ref="subItems")
      .main-menu-mobile__sub-items--back.is-flex(v-if="selectedItem", @click="onCollapseHandler")
        i.fas.fa-chevron-left
        .main-menu-mobile__root-items--item-name.is-flex
          icon(v-if="selectedItem.icon", :icon-name="selectedItem.icon", icon-color="#2e2e2e")
          span {{ selectedItem.name }}
      component.main-menu-mobile__sub-items--item(v-if="selectedItem", :is="selectedItem.view", :item="selectedItem")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { emailsMap } from '~~/utils/definitions/defaults.js'
import chatMixin from '~~/mixins/chatMixin'

export default {
  name: 'MainMenuMobileComponent',

  components: {
    AccountComponent: () => import('./Account'),
    dyo: () =>
      import('~~/components/Layout/MainMenu/MainMenuMobile/Views/dyo.vue'),
    simple: () => import('./Views/simple'),
    QuickSearch: () =>
      import('~~/components/Layout/TopHeader/QuickSearch/QuickSearch'),
    TopHeaderSearchLineComponent: () =>
      import('~~/components/Layout/TopHeader/TopHeaderSearchLine')
  },

  mixins: [chatMixin],

  props: {
    items: {
      type: Array,
      default: () => []
    },

    visible: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    expand: false,
    selectedItem: null,
    menuStyle: {
      top: '50px'
    }
  }),

  computed: {
    ...mapGetters({
      cartItems: 'cart/items',
      favoriteItems: 'favorites/allListItems',
      compareListItems: 'compareList/allListItems'
    }),

    emailUrl() {
      return `mailto:${emailsMap[this.$site.name]}`
    },

    menuClassList() {
      const classList = {
        expand: this.expand
      }

      if (this.selectedItem) classList[this.selectedItem.class] = true

      return classList
    },

    sitesList() {
      return [
        {
          id: '2f6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.sapphiresOur'),
          icon: 'menuStone',
          href: 'https://www.thenaturalsapphirecompany.com/',
          color: '#6690FF'
        },
        {
          id: '4d6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.emeraldsOur'),
          icon: 'menuStone',
          href: 'https://emeralds.com/',
          color: '#00A87E'
        },
        {
          id: '3d6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.gemstonesOur'),
          icon: 'menuStone',
          href: 'https://naturalgemstones.com/',
          color: '#5F4ABF'
        }
      ]
    },

    subMenu() {
      return [
        {
          id: '4488b119-e5d9-4242-802b-a1db04af43cc',
          icon: 'account',
          name: this.$t('layout.header.secondLine.account'),
          on: this.onAccountClick
        },
        {
          id: '9aa077b6-660b-4571-8dad-41495b6ff5f3',
          icon: 'cart',
          name: this.$t('layout.header.secondLine.cart'),
          itemsCount: this.cartItems.length,
          to: {
            name: 'cart-index'
          }
        },
        {
          id: '04daad8a-89dd-44e9-9005-12646970e419',
          icon: 'wishlist',
          name: this.$t('layout.header.secondLine.favorites'),
          itemsCount: this.favoriteItems.length,
          to: {
            name: 'favorites'
          }
        },
        {
          id: '886f9e37-f5b7-4249-a441-92204236fb25',
          icon: 'compare',
          name: this.$t('layout.header.secondLine.compare'),
          itemsCount: this.compareListItems.length,
          to: {
            name: 'compare-list'
          }
        }
      ]
    }
  },

  watch: {
    visible(state) {
      if (!state) {
        this.expand = false
        this.selectedItem = null
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calcTopOffset)
  },

  mounted() {
    this.calcTopOffset()
    window.addEventListener('resize', this.calcTopOffset)
  },

  methods: {
    ...mapActions({
      setMenuState: 'app/setMenuState'
    }),

    onAccountClick() {
      if (!this.$store.state.auth.loggedIn) {
        this.$root.$emit('account-menu')
        return
      }
      this.$router.push(this.localePath({ name: 'account' }))
    },

    calcTopOffset() {
      const headerHeight = document.querySelector('header')?.clientHeight
      if (!headerHeight) return
      this.menuStyle.top = `${headerHeight}px`
    },

    onExpandHandler(item) {
      this.selectedItem = item
      this.expand = true
    },

    onCollapseHandler() {
      this.expand = false
      setTimeout(() => {
        this.selectedItem = null
      }, 500)
    }
  }
}
</script>

<style lang="sass" scoped>
.main-menu-mobile
  max-width: 100vw
  overflow: hidden
  position: fixed
  z-index: 1999
  overflow-y: auto
  top: 95px
  bottom: 0
  left: 0
  right: 0
  +tablet-only
    width: 50%
    right: 0
    left: unset
  &__menus-wrapper
    width: 100%
  &__wrapper
    position: absolute
    overflow: hidden
    transition: margin-left 0.3s ease
    left: 0
    top: 0
    width: 200%
    height: fit-content
    min-height: 100vh
    background-color: #ffffff
    &.expand
      margin-left: -100%
  &__root-items,
  &__user-controls,
  &__sub-items
    width: 100%
    height: fit-content
  &__sub-items,
  &__user-controls,
  &__quick-search,
  &__contacts,
  &__sites
    border-top: 11px solid $light-primary-color
  &__root-items--item,
  &__user-controls--item,
  &__sub-items--back
    padding: 12px 20px
    cursor: pointer
    justify-content: space-between
    align-items: center
    .fa-chevron-right
      font-weight: 600
      opacity: 0.6
  &__root-items--item
    text-transform: uppercase
    &:not(:first-child)
      border-top: 1px solid $border-color
  &__contacts
    padding: 20px
  &__sites--title,
  &__contacts--phone
    text-align: center
  &__contacts--email,
  &__contacts--chat,
  &__sites--item
    font-family: $lato
    align-items: center
    width: 100%
    span
      margin-left: 10px
  &__contacts--email
    margin-top: 20px
  &__contacts--chat
    margin-top: 12px
  &__sites--title
    margin-bottom: 20px
  &__sites
    padding: 20px
    padding-bottom: 100px // for userlike
    a + a
      margin-top: 12px
  &__quick-search
    padding: 20px 32px
    +touch
      padding: 20px
    ::v-deep
      .quick-search
        flex-direction: row-reverse
        position: relative
        +tablet-only
          justify-content: flex-end
      .quick-search-input
        input
          background-color: #ffffff
  &__user-controls--item
    border-top: 1px solid $border-color
    a
      width: 100%
      justify-content: space-between
      align-items: center
    &:first-child
      border-top: unset
    .badge
      background-color: #2e2e2e
      border-radius: 50%
      font-size: 8px
      font-weight: bold
      letter-spacing: -0.16px
      color: white
      width: 16px
      height: 16px
      text-align: center
      display: flex
      align-items: center
      justify-content: center
  &__sub-items--back
    font-weight: bold
    text-transform: uppercase
    border-bottom: 12px solid $light-primary-color
    justify-content: unset
    .main-menu-mobile__root-items--item-name
      margin-left: 15px
  &__root-items--item-name,
  &__user-controls--item-name
    align-items: center
    svg + span
      margin-left: 5px
</style>
