<script>
import MainMenuMobile from '~~/components/Layout/MainMenu/MainMenuMobile/MainMenuMobile'

export default {
  name: 'MainMenuMobileComponent',

  components: {
    dyo: () => import('../MainMenuDesktop/Views/dyo'),
    simple: () => import('../MainMenuDesktop/Views/simple'),
    rings: () => import('../MainMenuDesktop/Views/rings'),
    stones: () => import('../MainMenuDesktop/Views/stones'),
    settings: () => import('../MainMenuDesktop/Views/settings.vue'),
    jewelry: () => import('../MainMenuDesktop/Views/jewelry.vue')
  },

  extends: MainMenuMobile,

  computed: {
    sitesList() {
      return [
        {
          id: '2f6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.sapphiresOur'),
          icon: 'menuStone',
          href: 'https://www.thenaturalsapphirecompany.com/',
          color: '#6690FF'
        },
        {
          id: '3d6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.rubiesOur'),
          icon: 'menuStone',
          href: 'https://thenaturalrubycompany.com/',
          color: '#bf1c60'
        },
        {
          id: '4d6f6f4b-7f0c-4a2c-9b1b-5b7d9b1b7d9b',
          name: this.$t('layout.mainMenu.emeraldsOur'),
          icon: 'menuStone',
          href: 'https://emeralds.com/',
          color: '#00A87E'
        }
      ]
    }
  }
}
</script>
